// Footer.js

import { Link } from "react-router-dom";

const Footer = () => {
    return (
      <footer className="text-center bg-slate-900 text-slate-500 py-2">
        <div className="border-slate-700 border-t py-2 md:w-9/12 md:mx-auto">
        <div className="text-sm font-mono">Developed by Anand Krishna © {new Date().getFullYear()}</div>
        <div className="text-sm font-mono">Design inspired by <Link to={`https://v4.brittanychiang.com/`} className="hover:text-teal-300">brittanychiang.com</Link> </div>
        </div>
      </footer>
    );
  };
  
export default Footer;