import profile from "../icons/profile.png"
function Home() {
  return (
    <div className="flex-grow bg-slate-900 text-slate-200">
      <div className="py-20 mx-auto md:w-1/2 w-3/4">
        <div className="font-mono text-teal-300 py-1">Hi, I'm</div>
        <div className="font-sans py-4 font-medium md:text-6xl text-4xl">Anand Krishna.</div>
        <div className="py-2">
        <div className="md:text-3xl text-xl font-medium font-sans text-slate-300">Crafting business solutions through <span className="text-teal-300">code</span> and <span className="text-teal-300">creativity.</span></div>
        </div>
        <div className="font-sans py-4 font-medium md:text-xl md:w-3/4 text-slate-400">Developer with a passion for <span className="text-teal-300">problem-solving, </span>building impactful <span className="">applications, </span> and exploring innovative <span className="">technologies.</span></div>
      </div>

      {/* <div className="md:columns-2 mx-auto md:w-1/2 w-3/4">
        <img className="border-b-2 border-l-2 border-teal-300 md:w-1/2 w-3/4 md:mx-0 mx-auto" src={profile} />
        <div className="" >Work Ex</div>
      </div> */}
      <div className="mx-auto w-3/4 py-20 md:columns-2">
        <img className="border-b-2 border-l-2 border-teal-300 md:w-1/3 w-1/2 mx-auto my-5" src={profile} />
        <div className="md:inline-block mx-auto">

        <div className="flex flex-row items-center">
          <div className=" flex-grow-0 font-sans text-slate-400 font-medium py-5 pr-4 md:text-xl text-lg">Work Experience</div>
          <div className=" flex-grow h-[1px] bg-slate-700"></div>
        </div>

          {/* <div className="md:pt-0 py-5 font-mono text-teal-300">Work Experience</div> */}
          <div className="columns-2">
            <div className="font-sans text-slate-300 font-medium md:text-xl text-lg">Genpact</div>
            <span className="font-mono text-teal-300 float-right">'23 - present</span>
          </div>
          <div className="font-sans text-slate-300 font-medium md:text-lg">Python Developer & Data Scientist</div>
          <div className="py-1 font-sans text-slate-400">
            <div className="py-1">Automated <span className="text-teal-300">data extraction</span> and reporting processes using Python and <span className="text-teal-300">Selenium</span>, significantly reducing execution time and manual interventions.</div>
            <div className="py-1">Conducted <span className="text-teal-300">exploratory data analysis</span> on troubleshooting data, enabling downtime prevention and informed decision-making.</div>
            <div className="py-1">Developed and optimized Python scripts for tasks like ticket generation and <span className="text-teal-300">text-to-speech</span> applications</div>
          </div>
        </div>
      </div>

      <div className="py-20 mx-auto md:w-1/2 w-3/4">
        <div className="flex flex-row items-center">
          <div className=" flex-grow-0 font-sans text-slate-400 font-medium py-10 pr-4 md:text-xl text-lg">Technologies I work with</div>
          <div className=" flex-grow h-[1px] bg-slate-700"></div>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className="font-mono text-teal-300">Languages :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">Python, R, JavaScript</span>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className=" font-mono text-teal-300">Frameworks :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">React.js, Express.js, Flask, TensorFlow, Scikit-Learn, Pandas, Matplotlib</span>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className=" font-mono text-teal-300">Machine Learning :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">Supervised and Unsupervised Learning, NLP, Pattern Recognition</span>
        </div>
        <div className="py-2 md:text-lg mx-auto">
          <span className=" font-mono text-teal-300">Tools :</span>
          <span className="pl-2 font-sans text-slate-200 font-medium">Git, Jupyter Notebook, VS Code, PyCharm</span>
        </div>
      </div>
    </div>
  );
}

export default Home;
