import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <nav className="bg-slate-900 text-slate-400 font-sans">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 border-slate-700 border-b">
        <a className="flex items-center"></a>
        <ul className="font-medium flex md:flex-row md:space-x-8 md:mt-0">
          <li className="relative group">
            <Link to="/home" className="inline-block py-2 pl-3 pr-4 md:p-0 hover:text-teal-300">
              Home
              <span className="block h-[2px] w-0 bg-teal-300 transition-all duration-200 ease-out group-hover:w-full"></span>
            </Link>
          </li>
          <li className="relative group">
            <Link to="/about" className="inline-block py-2 pl-3 pr-4 md:p-0 hover:text-teal-300">
              About
              <span className="block h-[2px] w-0 bg-teal-300 transition-all duration-200 ease-out group-hover:w-full"></span>
            </Link>
          </li>
        </ul>
        {/* <hr className='border-slate-400 mx-auto w-9/12'></hr> */}
      </div>
    </nav>
  );
}

export default Header;